import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const MailChimpFormWrapper = styled.div`
  position: relative;
  max-width: 500px;
  margin: auto;
`;

export const MailChimpFormContainer = styled.form`
  //padding: 20px;
  //background-color: ${themeGet('brand.brand5Tint80')};
  //border-radius: 10px;
`;

export const MailChimpFormHeader = styled.div`
  position: relative;
  padding-top: 30px;
  padding-right: 100px;

  .MailChimpFormAppLogo {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const MailChimpFormGroup = styled.div`
  .reusecore__input {
    input {
      width: 100%;
      margin-bottom: 10px;
      border: 1px solid ${themeGet('brand.brand8Tint80')};
      color: ${themeGet('brand.brand8Tint10')};
      background-color: ${themeGet('colors.white', '#ffffff')};

      &::placeholder {
        color: ${themeGet('brand.brand8Tint40')};
      }

      &:focus {
        border-color: ${themeGet('brand.brand7Tint50')};
      }
    }

    &.invalid {
      input {
        border-color: #f96587;
      }
    }

    &.valid {
      input {
        border-color: ${themeGet('brand.brand3Tint40')};
      }
    }
  }

  .reusecore__checkbox {
    &.invalid {
      label {
        .reusecore__field-label {
          color: #c5294c;
        }

        div {
          border: 1px solid #f96587;
        }
      }
    }
  }
`;

export const MailChimpFormGDPR = styled.div`
  color: ${themeGet('brand.brand8Tint20')};
  text-align: left;
  line-height: 1.4;
  font-size: 14px;

  .PrivacyText {
    padding-top: 10px;

    .checkbox {
      position: relative;
      top: 13px;
    }
  }

  .PrivacyText__check {
    font-size: 10px;

    label {
      align-items: flex-start;
      cursor: pointer;

      div {
        position: relative;
        top: 2px;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .PrivacyLink {
    color: ${themeGet('brand.brand6')};
  }
`;

export const MailChimpFormAction = styled.div`
  text-align: center;
  padding: 40px 0;
`;

export const MailChimpFormFooter = styled.div`
  color: ${themeGet('brand.brand8Tint20')};
  line-height: 1.4;
  font-size: 14px;
  text-align: center;
`;

export const MailChimpFormMessage = styled.div`
  color: ${themeGet('brand.brand8')};
  line-height: 1.3;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 50px;
  padding: 10px;
  border-radius: 10px;
  background: ${themeGet('brand.brand1')};

  span {}

  a {
    color: ${themeGet('brand.brand6')};
  }
`;

export default MailChimpFormWrapper;
