import React, { useState } from 'react';
import PropTypes from 'prop-types';
import addToMailchimp from 'gatsby-plugin-mailchimp';

// Components
// =============================================================================
import Checkbox from 'common/src/components/Checkbox';
import Button from 'common/src/components/Button';
import Heading from 'common/src/components/Heading';
import Input from 'common/src/components/Input';
import Image from 'common/src/components/Image';

// Styles
// =============================================================================
import MailChimpFormWrapper, {
  MailChimpFormContainer,
  MailChimpFormHeader,
  MailChimpFormGroup,
  MailChimpFormGDPR,
  MailChimpFormAction,
  MailChimpFormFooter,
  MailChimpFormMessage,
} from './MailChimpForm.style';

// Colours
// =============================================================================
import brand from 'common/src/theme/landing/brand';

// Images
// =============================================================================
//import AppLogo from 'common/src/assets/image/landing/app-logo.png';

const MailChimpForm = ({
  handleFnameChange,
  handleLnameChange,
  handleJobtChange,
  handleCompanyChange,
  handleCompanyEmailChange,
  handleGDPRchange,
  handleMailChimpSubmit,
  FormHeadingStyle,
  FormLogoStyle,
  FormButtonStyle,
  formHeading,
  formLogo,
  formButton,
  formLink,
  formPageType,
}) => {
  const [state, setState] = useState({
    first_name: '',
    first_name_valid: '',
    last_name: '',
    last_name_valid: '',
    job_title: '',
    job_title_valid: '',
    company: '',
    company_valid: '',
    company_email: '',
    company_email_valid: '',
    form_link: formLink,
    landing_type: formPageType,
    cta_button: '',
    gdpr_checked: false,
    custom_gdpr: '',
    custom_gdpr_valid: '',
    result: null,
    result_msg: null,
  });

  // Handle First Name Input
  handleFnameChange = (value) => {
    if (value.length > 0) {
      setState({
        ...state,
        first_name: value,
        first_name_valid: 'valid',
      });
    } else {
      setState({
        ...state,
        first_name: value,
        first_name_valid: 'invalid',
      });
    }
  };

  // Handle Last Name Input
  handleLnameChange = (value) => {
    if (value.length > 0) {
      setState({
        ...state,
        last_name: value,
        last_name_valid: 'valid',
      });
    } else {
      setState({
        ...state,
        last_name: value,
        last_name_valid: 'invalid',
      });
    }
  };

  // Handle Job Title Input
  handleJobtChange = (value) => {
    if (value.length > 0) {
      setState({
        ...state,
        job_title: value,
        job_title_valid: 'valid',
      });
    } else {
      setState({
        ...state,
        job_title: value,
        job_title_valid: 'invalid',
      });
    }
  };

  // Handle Company Input
  handleCompanyChange = (value) => {
    if (value.length > 0) {
      setState({
        ...state,
        company: value,
        company_valid: 'valid',
      });
    } else {
      setState({
        ...state,
        company: value,
        company_valid: 'invalid',
      });
    }
  };

  // Email Regex
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line

  // Handle Company Email Input
  handleCompanyEmailChange = (value) => {
    if (value.match(emailRegex)) {
      if (value.length > 0) {
        setState({
          ...state,
          company_email: value,
          company_email_valid: 'valid',
        });
      }
    } else {
      if (value.length > 0) {
        setState({
          ...state,
          company_email: value,
          company_email_valid: 'invalid',
        });
      } else {
        setState({
          ...state,
          company_email: value,
          company_email_valid: 'invalid',
        });
      }
    }
  };

  // Handle GDPR Checkbox
  handleGDPRchange = (value) => {
    if (value.target.checked) {
      setState({
        ...state,
        gdpr_checked: !state.gdpr_checked,
        custom_gdpr_valid: 'valid',
      });
    } else {
      setState({
        ...state,
        gdpr_checked: false,
        custom_gdpr_valid: 'invalid',
      });
    }
  };

  // Handle MailChimp From Submit
  handleMailChimpSubmit = async (e) => {
    e.preventDefault();
    if (
      state.gdpr_checked &&
      state.first_name_valid === 'valid' &&
      state.last_name_valid === 'valid' &&
      state.company_email_valid === 'valid'
    ) {
      const result = await addToMailchimp(state.company_email, {
        FNAME: state.first_name,
        LNAME: state.last_name,
        JOBT: state.job_title,
        COMPANY: state.company,
        FLINK: state.form_link,
        LTYPE: state.landing_type,
        CTABTN: '', // TODO
        'group[16942][1]': '1', // custom GDPR (Email),
      });
      setState({
        ...state,
        result: result.result,
        result_msg: result.msg,
      });
    } else {
      if (
        state.first_name_valid === '' &&
        state.last_name_valid === '' &&
        state.company_email_valid === ''
      ) {
        setState({
          ...state,
          first_name_valid: 'invalid',
          last_name_valid: 'invalid',
          company_email_valid: 'invalid',
          custom_gdpr_valid: 'invalid',
        });

        // if (state.first_name_valid === '') {
        //   setState({
        //     ...state,
        //     first_name_valid: 'invalid'
        //   });

        // state.first_name_valid === '' || state.first_name_valid === 'invalid'
        //   ? setState({
        //       ...state,
        //       first_name_valid: 'invalid'
        //     })
        //   : setState({
        //       ...state,
        //       first_name_valid: 'valid'
        //     }) ||

        // state.last_name_valid === '' || state.last_name_valid === 'invalid'
        // ? setState({
        //     ...state,
        //     last_name_valid: 'invalid'
        //   })
        // : setState({
        //     ...state,
        //     last_name_valid: 'valid'
        //   });
      }
    }
  };

  return (
    <MailChimpFormWrapper>
      <MailChimpFormContainer id="mailchimp_form">
        <MailChimpFormHeader>
          {formLogo ? (
            <Image
              src={formLogo}
              {...FormLogoStyle}
              className="MailChimpFormAppLogo"
              alt="App Logo"
            />
          ) : null}
          <Heading {...FormHeadingStyle} content={formHeading} />
        </MailChimpFormHeader>
        <MailChimpFormGroup>
          <Input
            inputType="text"
            placeholder="First name *"
            iconPosition="left"
            aria-label="first_name"
            name="first_name"
            onChange={handleFnameChange}
            required={true}
            className={state.first_name_valid}
          />
          <Input
            inputType="text"
            placeholder="Last name *"
            iconPosition="left"
            aria-label="last_name"
            name="last_name"
            onChange={handleLnameChange}
            required={true}
            className={state.last_name_valid}
          />
          <Input
            inputType="text"
            placeholder="Job title"
            iconPosition="left"
            aria-label="job_title"
            name="job_title"
            onChange={handleJobtChange}
            className={state.job_title_valid}
          />
          <Input
            inputType="text"
            placeholder="Company"
            iconPosition="left"
            aria-label="company"
            name="company"
            onChange={handleCompanyChange}
            className={state.company_valid}
          />
          <Input
            inputType="email"
            placeholder="Company email *"
            iconPosition="left"
            aria-label="company_email"
            name="company_email"
            onChange={handleCompanyEmailChange}
            required={true}
            className={state.company_email_valid}
          />
          <MailChimpFormGDPR>
            <div className="PrivacyText">
              <Checkbox
                value="gdpr"
                labelPosition="right"
                labelText="Check this box to receive communications and offers from Cybertonica."
                onChange={handleGDPRchange}
                required={true}
                checked={state.gdpr_checked}
                className={`PrivacyText__check ${state.custom_gdpr_valid}`}
              />
            </div>
            <div className="PrivacyText">
              To find out how we use and protect your data, read our{' '}
              <a
                href="https://cybertonica.com/privacy-policy?ref=discover"
                className="PrivacyLink"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </div>
          </MailChimpFormGDPR>
          <MailChimpFormAction>
            <Button
              title={formButton}
              aria-label="submit"
              onClick={handleMailChimpSubmit}
              {...FormButtonStyle}
            />
          </MailChimpFormAction>
        </MailChimpFormGroup>
        <MailChimpFormFooter>
          <span>
            We respect your privacy and promise to
            <br />
            protect your personal information.
          </span>
          {state.result_msg ? (
            <MailChimpFormMessage>
              <span
                dangerouslySetInnerHTML={{ __html: state.result_msg }}
              ></span>
            </MailChimpFormMessage>
          ) : null}
        </MailChimpFormFooter>
      </MailChimpFormContainer>
    </MailChimpFormWrapper>
  );
};

MailChimpForm.propTypes = {
  FormButtonStyle: PropTypes.object,
};

MailChimpForm.defaultProps = {
  FormLogoStyle: {
    maxWidth: ['60px', '60px', '70px', '70px', '70px'],
  },
  FormHeadingStyle: {
    fontSize: ['20px', '20px', '20px', '22px', '24px'],
    fontWeight: '600',
    color: brand.brand6Shade20,
    letterSpacing: '0em',
    mb: '20px',
    lineHeight: '1.35',
    textAlign: 'left',
  },
  FormButtonStyle: {
    type: 'button',
    fontSize: ['15px', '17px'],
    fontWeight: '500',
    colors: 'cyber6WithBg',
    minHeight: '44px',
    pt: '0',
    pb: '0',
    pl: ['15px', '30px'],
    pr: ['15px', '30px'],
  },
};

export default MailChimpForm;
